import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor{

    constructor(private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let accessToken: string;
        try {
            accessToken = sessionStorage.getItem("access_token");
        }
        catch {
            accessToken = "cannot access storage";
        }

        if (accessToken) {
            req = req.clone({
            setHeaders: {
                Authorization: `Bearer ${accessToken}`
            }
            });
        }

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.router.navigate(['admin/login']);
                }
                return throwError(error);
              })
        );
    }
}

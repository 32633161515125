import { Component, OnInit, Renderer2 } from '@angular/core';
import { SpinnerService } from '../../services/spinner.service';
import { BasePageComponent } from '../bases/base-page-component';

@Component({
    selector: 'app-page-not-found-component',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent extends BasePageComponent implements OnInit {
    public title = 'Page Not Found';

    constructor(
        private spinnerService: SpinnerService, private renderer: Renderer2){
            super();
    }

    ngOnInit(): void {
        this.spinnerService.setupShowOnBodyUntil(this.renderer, this.onDestroy$);
    }
}

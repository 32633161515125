import { OnDestroy } from '@angular/core';
import { UserService } from 'src/app/modules/core/services/user.service';
import { AppUserModel } from 'src/app/models/users/app-user.model';
import { Strings } from 'src/app/configs/strings';
import { Subject, Subscription } from 'rxjs';

export class BaseComponent implements OnDestroy {
    protected onDestroy$ = new Subject<void>();
    public strings = Strings;


    constructor() {
    }

    public ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}

import { Renderer2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import * as i0 from "@angular/core";
//these must be declared here
//the interceptor service uses a different instance
//than the version injected into components so these must be declared globally
const _spinnerShown$ = new BehaviorSubject(false);
let shownCount = 0;
export class SpinnerService {
    constructor() {
    }
    showUntilFinished(observable) {
        this.show();
        return observable.pipe(finalize(() => this.hide()));
    }
    get spinnerShown$() {
        return _spinnerShown$.asObservable();
    }
    show() {
        shownCount++;
        if (shownCount == 1)
            _spinnerShown$.next(true);
    }
    hide() {
        shownCount--;
        if (shownCount <= 0) {
            _spinnerShown$.next(false);
            shownCount = 0;
        }
    }
    setupShowOnBodyUntil(renderer, onDestroy) {
        this.spinnerShown$
            .pipe(takeUntil(onDestroy))
            .subscribe(showSpinner => {
            if (showSpinner) {
                renderer.addClass(document.body, 'spinner');
            }
            else {
                renderer.removeClass(document.body, 'spinner');
            }
        });
    }
    intercept(req, next) {
        let requestPipeline = next.handle(req);
        return this.showUntilFinished(requestPipeline);
    }
}
SpinnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SpinnerService_Factory() { return new SpinnerService(); }, token: SpinnerService, providedIn: "root" });

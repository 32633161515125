import { Routes } from '@angular/router';
import { PageNotFoundComponent } from 'src/app/modules/core/components/page-not-found/page-not-found.component';
const ɵ0 = () => import("./modules/frontend/frontend.module.ngfactory").then(m => m.FrontendModuleNgFactory), ɵ1 = () => import("./modules/backend/backend.module.ngfactory").then(m => m.BackendModuleNgFactory);
const appRoutes = [
    {
        path: '',
        loadChildren: ɵ0
    },
    {
        path: 'admin',
        loadChildren: ɵ1
    },
    { path: 'pageNotFound', component: PageNotFoundComponent },
    { path: '**', component: PageNotFoundComponent }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1 };

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

function setGoogleMapApiKey() {
    if (location.hostname.includes('trofaco-dev')
        || location.hostname.includes('trofaco-demo')) {
        // development key
        window['googleMapApiKey'] = 'AIzaSyDnkV0RgOjgh2vGHHEvt1jFw3BXE2sbCwc';
    } else if (location.hostname.includes('localhost')) {
        window['googleMapApiKey'] = undefined;
    } else {
        // production key
        window['googleMapApiKey'] = 'AIzaSyDDak09zdNQWwEpdez2rsG6fPs5VgioBOY';
    }
}

function pageReady() {
    const body = document.getElementsByTagName('app-root')[0];
    body.className += ' active';
}

function boostrap() {
    platformBrowserDynamic().bootstrapModule(AppModule)
        .then(() => pageReady())
        .catch(err => console.log(err));
}

function startApp() {
    setGoogleMapApiKey();
    boostrap();
}

startApp();

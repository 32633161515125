<div *ngIf="!accessStorage" class="frontend">
    <div class="cookie-warning">
        <div class="cookie-warning-icon">
            <img src="assets/img/brand/trofaco-logo.png" alt="" />
        </div>
        <div class="cookie-warning-content">
            If you would like so see this embedded content, please allow third party cookies in your browser.
        </div>
    </div>
</div>
<router-outlet *ngIf="accessStorage"></router-outlet>

import { Component, OnInit, Renderer2  } from '@angular/core';
import { BasePageComponent } from './modules/core/components/bases/base-page-component';
import { SpinnerService } from './modules/core/services/spinner.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent extends BasePageComponent implements OnInit {
    public accessStorage = true;


    constructor(private spinnerService: SpinnerService, private renderer: Renderer2){   
        super();        
    }

    ngOnInit(): void {
        try {
            if (localStorage.getItem) {
                console.log('allow access storage');             
            }
            else {
                this.accessStorage = false;
                console.log('not allow access storage');
            }
        }
        catch {
            this.accessStorage = false;
            console.log('not allow access storage');
        }
        this.spinnerService.setupShowOnBodyUntil(this.renderer, this.onDestroy$);
    }
}

import { LazyMapsAPILoaderConfigLiteral } from '@agm/core';
const ɵ0 = (config) => {
    if (window.location.hostname.includes('trofaco-dev')
        || location.hostname.includes('trofaco-demo')) {
        // development key
        config.apiKey = 'AIzaSyDnkV0RgOjgh2vGHHEvt1jFw3BXE2sbCwc';
    }
    else if (location.hostname.includes('localhost')) {
        config.apiKey = undefined;
    }
    else {
        // production key
        config.apiKey = 'AIzaSyDDak09zdNQWwEpdez2rsG6fPs5VgioBOY';
    }
    config.libraries = ['geometry'];
    return () => Promise.resolve();
};
export class AppModule {
    constructor(previousRouteService) {
        previousRouteService.ngOnInit();
    }
}
export { ɵ0 };

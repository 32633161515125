import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class AuthInterceptorService {
    constructor(router) {
        this.router = router;
    }
    intercept(req, next) {
        let accessToken;
        try {
            accessToken = sessionStorage.getItem("access_token");
        }
        catch (_a) {
            accessToken = "cannot access storage";
        }
        if (accessToken) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
        }
        return next.handle(req).pipe(catchError((error) => {
            if (error.status === 401) {
                this.router.navigate(['admin/login']);
            }
            return throwError(error);
        }));
    }
}
AuthInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthInterceptorService_Factory() { return new AuthInterceptorService(i0.ɵɵinject(i1.Router)); }, token: AuthInterceptorService, providedIn: "root" });

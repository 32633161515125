import { Injectable, Injector, Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

//these must be declared here
//the interceptor service uses a different instance
//than the version injected into components so these must be declared globally
const _spinnerShown$ = new BehaviorSubject(false);
let shownCount = 0;

@Injectable({
    providedIn: 'root'
})
export class SpinnerService implements HttpInterceptor{


    constructor() {
    }

    showUntilFinished<T>(observable: Observable<T>): Observable<T> {
        this.show();
        return observable.pipe(finalize(() => this.hide()));
    }

    get spinnerShown$() {
        return _spinnerShown$.asObservable();
    }

    show() {
        shownCount++;
        if (shownCount == 1)
            _spinnerShown$.next(true);
    }

    hide() {
        shownCount--;
        if (shownCount <= 0) {
            _spinnerShown$.next(false);
            shownCount = 0;
        }
    }

    setupShowOnBodyUntil(renderer:Renderer2, onDestroy: Observable<any>) {
        this.spinnerShown$
            .pipe(takeUntil(onDestroy))
            .subscribe(showSpinner => {
                if (showSpinner) {
                    renderer.addClass(document.body, 'spinner')
                } else {
                    renderer.removeClass(document.body, 'spinner')
                }
            });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let requestPipeline = next.handle(req);
        return this.showUntilFinished(requestPipeline);
    }
}

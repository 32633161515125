import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { PageNotFoundComponent } from 'src/app/modules/core/components/page-not-found/page-not-found.component';
import { SpinnerComponent } from 'src/app/modules/core/components/spinner/spinner.component';
import { PlantingSitesShellComponent } from './components/planting-sites-shell/planting-sites-shell.component';
import { FilterListComponent } from './components/planting-sites-shell/filter-list.component';
import { FilterCheckboxTreeComponent } from './components/filter-checkbox-tree/filter-checkbox-tree.component';
import { SubFiltersSelectedPipe } from './components/filter-checkbox-tree/sub-filters-selected.pipe';
import { IntermediateDirective } from './directives/intermediate.directive';
import { YearPickerComponent } from './components/year-picker/year-picker.component';
import { PromptUnsubmittedDirective } from './directives/prompt-unsubmitted.directive';
import { MapPickerComponent } from './components/map-picker/map-picker.component';
import { AgmCoreModule } from '@agm/core';
import { MapPickerModalComponent } from './components/map-picker/map-picker-modal.component';
import { IsInvalidDirective } from './directives/is-invalid.directive';
import { LatLonToPointPipe } from './components/map-picker/lat-lon-to-point.pipe';
import { HasDecimalPipe } from './pipes/has-decimal.pipe';
import { ConfirmDeleteModalComponent } from './components/delete-modal/confirm-delete-modal-component';
import { AutoCompleteOffDirective } from './directives/auto-complete-off.directive';
import { AutosizeDirective } from './directives/autosize.directive';
import { NextPhotoIdPipe } from './pipes/next-photo-id.pipe';
import { PreviousPhotoIdPipe } from './pipes/previous-photo-id.pipe';
import { SitesShellFrontendComponent } from './components/planting-sites-shell/sites-shell-frontend/sites-shell-frontend.component';
import { BackgroundUrlDirective } from './directives/background-url.directive';
import { HeaderPhotoPipe } from './pipes/header-photo.pipe';
import { VideoThumbnailPipe } from './pipes/video-thumbnail.pipe';
import { FilterNoPhotosPipe } from './pipes/filter-no-photos.pipe';
import { SiteSpeciesPipe } from './pipes/site-species.pipe';
import { HasSingleDonorPipe } from './pipes/has-single-donor.pipe';
import { SiteMapIconPipe } from './pipes/site-map-icon.pipe';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AppHeaderModule } from '@coreui/angular';
import { TransformEmailPipe } from './pipes/transform-email.pipe';
import { CustomerTypePipe } from './pipes/customer-type.pipe';
import { FilterDropDownComponent } from './components/filter-dropdown/filter-dropdown.component';
import { OnlyVisibleDonorPipe } from './pipes/only-visible-donor.pipe';
import { HasVisibleDonorPipe } from './pipes/has-visible-donor.pipe';
import { TotalCertificatesTreePipe } from './pipes/total-certificates-tree.pipe';
import { UserRolePipe } from './pipes/user-role.pipe';
import { UserRole } from 'src/app/models/users/user-role';
import { BsDropdownModule, PopoverModule } from 'ngx-bootstrap';
import { PopoverComponent } from './components/popover/popover.component';

@NgModule({
    declarations: [
        PageNotFoundComponent,
        SpinnerComponent,
        PlantingSitesShellComponent,
        FilterListComponent,
        FilterCheckboxTreeComponent,
        SubFiltersSelectedPipe,
        IntermediateDirective,
        YearPickerComponent,
        PromptUnsubmittedDirective,
        MapPickerComponent,
        MapPickerModalComponent,
        IsInvalidDirective,
        LatLonToPointPipe,
        HasDecimalPipe,
        ConfirmDeleteModalComponent,
        AutoCompleteOffDirective,
        AutosizeDirective,
        NextPhotoIdPipe,
        PreviousPhotoIdPipe,
        SitesShellFrontendComponent,
        BackgroundUrlDirective,
        HeaderPhotoPipe,
        VideoThumbnailPipe,
        FilterNoPhotosPipe,
        OnlyVisibleDonorPipe,
        HasVisibleDonorPipe,
        SiteSpeciesPipe,
        HasSingleDonorPipe,
        SiteMapIconPipe,
        TransformEmailPipe,
        CustomerTypePipe,
        FilterDropDownComponent,
        TotalCertificatesTreePipe,
        UserRolePipe,
        PopoverComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        RouterModule,
        AgmCoreModule,
        CollapseModule.forRoot(),
        ModalModule.forRoot(),
        NgSelectModule,
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        PaginationModule.forRoot(),
        AppHeaderModule,
        PopoverModule.forRoot(),
    ],
    providers: [

    ],
    exports: [
        CommonModule,
        FormsModule,
        PageNotFoundComponent,
        SpinnerComponent,
        PlantingSitesShellComponent,
        YearPickerComponent,
        PromptUnsubmittedDirective,
        MapPickerModalComponent,
        MapPickerComponent,
        IsInvalidDirective,
        LatLonToPointPipe,
        HasDecimalPipe,
        ConfirmDeleteModalComponent,
        AutoCompleteOffDirective,
        AutosizeDirective,
        PreviousPhotoIdPipe,
        NextPhotoIdPipe,
        SitesShellFrontendComponent,
        BackgroundUrlDirective,
        HeaderPhotoPipe,
        VideoThumbnailPipe,
        FilterNoPhotosPipe,
        SiteSpeciesPipe,
        HasSingleDonorPipe,
        OnlyVisibleDonorPipe,
        HasVisibleDonorPipe,
        SiteMapIconPipe,
        ModalModule,
        NgSelectModule,
        BsDatepickerModule,
        PaginationModule,
        AppHeaderModule,
        CustomerTypePipe,
        FilterDropDownComponent,
        TotalCertificatesTreePipe,
        UserRolePipe,
        PopoverComponent
    ]
})
export class CoreModule { }

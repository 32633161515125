import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CoreModule } from 'src/app/modules/core/core.module';
import { AppRoutingModule } from 'src/app/app.routes';
import { AppComponent } from './app.component';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG, LazyMapsAPILoaderConfigLiteral } from '@agm/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SpinnerService } from './modules/core/services/spinner.service';
import { AuthInterceptorService } from './modules/core/services/auth-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { AppRouteStrategy } from './app-route-strategy';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { PreviousRouteService } from './modules/core/services/previous-route.service';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserAnimationsModule,
        CoreModule,
        AppRoutingModule,
        AgmCoreModule.forRoot({
            apiKey: 'initialKey',
            libraries: ['geometry']
        }),
        AgmSnazzyInfoWindowModule,
        AgmJsMarkerClustererModule
    ],
    providers: [
        {provide: RouteReuseStrategy, useClass: AppRouteStrategy},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SpinnerService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (config: LazyMapsAPILoaderConfigLiteral) => {
                if (window.location.hostname.includes('trofaco-dev')
                    || location.hostname.includes('trofaco-demo')) {
                    // development key
                    config.apiKey = 'AIzaSyDnkV0RgOjgh2vGHHEvt1jFw3BXE2sbCwc';
                } else if (location.hostname.includes('localhost')) {
                    config.apiKey = undefined;
                } else {
                    // production key
                    config.apiKey = 'AIzaSyDDak09zdNQWwEpdez2rsG6fPs5VgioBOY';
                }
                config.libraries = ['geometry'];
                return () => Promise.resolve();
            },
            deps: [LAZY_MAPS_API_CONFIG],
            multi: true
        },
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
    constructor(previousRouteService: PreviousRouteService) {
        previousRouteService.ngOnInit();
    }
}

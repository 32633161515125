import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from 'src/app/modules/core/components/page-not-found/page-not-found.component';
import { PreloadAllModules } from '@angular/router';

const appRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('./modules/frontend/frontend.module').then(m => m.FrontendModule)
    },
    {
        path: 'admin',
        loadChildren: () => import('./modules/backend/backend.module').then(m => m.BackendModule)
    },
    {path: 'pageNotFound', component: PageNotFoundComponent},
    {path: '**', component: PageNotFoundComponent}
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            {
                preloadingStrategy: PreloadAllModules,
                enableTracing: false,
                scrollPositionRestoration: 'disabled'
            }
        )
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {}
